/* eslint-disable no-class-assign */
/* eslint-disable react/prop-types */
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import  {  } from 'react-router-dom'
import clsx from 'clsx'

import Moment from 'react-moment';
import 'moment/locale/es';

// Externals
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

// Material helpers
import { Box, MenuItem, Tooltip, withStyles } from '@material-ui/core';
 
import {
  IconButton,
  Popover,
  Toolbar,
  Typography,
  Button,
  Menu
} from '@material-ui/core';

// Material icons
import {
  Menu as MenuIcon,
  Close as CloseIcon,
  Language as LanguageIcon,
  ArrowLeft,
  ArrowRight
} from '@material-ui/icons';

// Shared services
import { getNotifications } from 'services/notification';

// Custom components
import { NotificationList } from './components';

// Component styles
import styles from './styles';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { changeUserLanguage } from 'js/actions/prefsActions';
import { isAllowed } from 'helpers';
import moment from 'moment';
import { DatePick } from 'components/DatePicker';


var today = new Date();

Moment.globalFormat = 'D MMMM YYYY';
Moment.globalFilter = (d) => {
  return d.toUpperCase();
}
 
const mapDispatchToProps = dispactch => {
  return {
    postPrefs: prefs =>
      dispactch(changeUserLanguage(prefs))
  }
}


class Topbar extends Component {

  constructor(props){
    super(props);
    this.goBack = this.goBack.bind(this);
    this.state = {
      menuOpen: false,
      anchorEl: null,
      showChargeName: false
    };
    this.languageMenuRef = React.createRef();
    
  }

  goBack(){
    this.props.history.goBack();
  }

  signal = true;


  state = {
    notifications: [],
    notificationsLimit: 4,
    notificationsCount: 0,
    notificationsEl: null,
  };
  

  
  async getNotifications() {
    try {
      const { notificationsLimit } = this.state;

      const { notifications, notificationsCount } = await getNotifications(
        notificationsLimit
      );

      if (this.signal) {
        this.setState({
          notifications,
          notificationsCount
        });
      }
    } catch (error) {
      return;
    }
  }

  componentDidMount() {
    this.signal = true;
    this.getNotifications();    
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    this.signal = false;
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    if (window.scrollY > 500) {
      this.setState({ showChargeName: true });
    } else {
      this.setState({ showChargeName: false });
    }
  };

  handleSignOut = () => {
    const { history } = this.props;
    localStorage.setItem('isAuthenticated', false);
    history.push('/sign-in');
  };

  handleShowNotifications = event => {
    this.setState({
      notificationsEl: event.currentTarget
    });
  };

  handleCloseNotifications = () => {
    this.setState({
      notificationsEl: null
    });
  };


  handleChangeLanguage = selLang => {
    const { postPrefs } = this.props
    localStorage.setItem('prefLang', selLang);
    localStorage.setItem('locale', selLang === 'SP' ? 'es' : 'en' );

    postPrefs({
      'prefLang': selLang,
      'locale': selLang === 'SP' ? 'es' : 'en'
    })
  };

  handleOpenMenu = () => {
    this.setState({ menuOpen: true, anchorEl: this.languageMenuRef.current });
  }

  handleCloseMenu = () => {
    this.setState({ menuOpen: false });
  }
  

  render() {


    const {
      classes,
      className,
      isSidebarOpen,
      onToggleSidebar,
      mobile
    } = this.props;
    const { notifications, notificationsEl, showChargeName } = this.state;
    const width = window.innerWidth;

    const rootClassName = classNames(classes.root, className);
    const showNotifications = Boolean(notificationsEl);
    const { intl, prefs, chargeName, month, year, handlePrevMonth, handleNextMonth, changeMonthYear, ids, setChangeMonthYear, setMonth, setYearChange } = this.props;

    const currentMonth = moment().month();
    const currentYear = moment().year();

    Moment.globalLocale = prefs.locale;

    const disableRightRow = month === undefined && year === undefined ? true : currentMonth === (month - 1)  && currentYear === year ? true : false;

    return (
      <Fragment>
        <div className={rootClassName}>
          <Toolbar className={classes.toolbar}>
            {!mobile ? 
              <IconButton
                className={clsx(classes.menu, {
                  [classes.menuButton]: isSidebarOpen && !mobile,
                  [classes.menuButtonOpen]: !isSidebarOpen && mobile,
                })}
                onClick={onToggleSidebar}
                variant="text"
              >
                {isSidebarOpen || mobile ? <CloseIcon /> : <MenuIcon />}
              </IconButton> :
              <IconButton
                className={classes.menuButtonMobile}
                onClick={onToggleSidebar}
                variant="text"
              >
                {!isSidebarOpen && !mobile ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
            }
            {/* <img
              alt=""
              //className={classes.logoImg}
              src={corporative.logo}
              width="60px"
              //onClick={()=>history.push('/')}
            /> */}
            {/* <Typography
              className={classes.title}
              variant="h3"
            >
              {corporative.name}
            </Typography> */}
            {chargeName && (
              <Typography
                className={clsx(classes.chargeName, {
                  [classes.chargeNameVisible]: showChargeName,
                })}
              >
                {chargeName}
              </Typography>
            )}
            {((this.props.location.pathname).includes('/dashboardCharge/group/') || (this.props.location.pathname).includes('dashboardSolarCharge/group/'))  && (
              <Box
                alignItems="center"
                className={clsx(classes.dateBox, {
                  [classes.dateVisible]: showChargeName,
                })}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                {width > 768 && (
                  <Tooltip
                    aria-label="prev-month"
                    title="Mes Anterior"
                  >
                    <Button
                      aria-label="prev-month"
                      color="secondary"
                      onClick={handlePrevMonth}
                      variant="contained"
                    >
                      <ArrowLeft />
                    </Button>
                  </Tooltip>
                )}
                <div>
                  <DatePick
                    changeMonthYear={changeMonthYear}
                    ids={ids}
                    setChangeMonthYear={setChangeMonthYear}
                    setMonth={setMonth}
                    setYearChange={setYearChange}
                  />
                </div>
                {width > 768 && (
                  <Tooltip
                    aria-label="next-month"
                    title="Mes Siguiente"
                  >
                    <Button
                      aria-label="next-month"
                      color="secondary"
                      disabled={disableRightRow}
                      onClick={handleNextMonth}
                      variant="contained"
                    >
                      <ArrowRight />
                    </Button>
                  </Tooltip>

                )}
              </Box>
              
            )}
            <Typography
              className={classes.notificationsButton}
              style={{fontWeight:'600'}}
              variant="body1"
            >
              <Moment date={today}/>
            </Typography>
            <Button
              aria-controls="language-menu"
              aria-haspopup="true"
              className={classes.hamburguerIcon}
              defaultValue={this.props.prefs.prefLang}
              indicator={null}
              onChange={this.handleChangeLanguage}
              onClick={this.handleOpenMenu}
              ref={this.languageMenuRef}
              sx={null}
              value={this.props.prefs.prefLang}
            >
              <LanguageIcon style={{ width: '16px'}} />
              <Typography className={classes.translate}>
                {this.props.prefs.prefLang === 'SP' ? 'ESP' : 'ENG'}
              </Typography>
            </Button>
            <Menu
              anchorEl={this.state.anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              id="language-menu"
              onClose={this.handleCloseMenu}
              open={this.state.menuOpen}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              <MenuItem
                onClick={() => { this.handleChangeLanguage('SP'); this.handleCloseMenu(); }} 
                value={'SP'}
              >
                ESP
              </MenuItem>
              <MenuItem
                onClick={() => { this.handleChangeLanguage('EN'); this.handleCloseMenu(); }} 
                value={'EN'}
              >
                ENG
              </MenuItem>
            </Menu>
            {!(this.props.location.pathname === '/home' || this.props.match.path === '/corporative/:corpId') ? (
              <Button
                className={classes.backButton}
                onClick={this.goBack}
                variant="contained"
              >
                {intl.formatMessage({id: 'app.topBar.returnBtn'})}
              </Button>) : ('')
            }

          </Toolbar>
        </div>
        <Popover
          anchorEl={notificationsEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          onClose={this.handleCloseNotifications}
          open={showNotifications}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <NotificationList
            notifications={notifications}
            onSelect={this.handleCloseNotifications}
          />
        </Popover>
      </Fragment>
    );
  }
}

Topbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isSidebarOpen: PropTypes.bool,
  onToggleSidebar: PropTypes.func,
  title: PropTypes.string
};

Topbar.defaultProps = {
  onToggleSidebar: () => {}
};

const mapStateToProps = state => {
  return {
    corporative: state.user.corporative , 
    prefs : state.prefs,
    user: state.user.user
  };
};

Topbar = injectIntl(Topbar);

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps,mapDispatchToProps)
)(Topbar);
