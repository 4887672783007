/* eslint-disable no-undef */
import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Grid } from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Custom components
import { Contact, List, VideoPlayer } from './components';

// Component styles
const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 4,
    fontFamily: 'Roboto, sans-serif'
  }
});

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videos: [],
      currentVideo: null,
      pdf: []
    };
  }

  handleVideo = (video) => {
    this.setState({ currentVideo: video });
  }

  render() {
    const { classes } = this.props;

    return (
      <DashboardLayout title="Ayuda">
        <div className={classes.root}>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={7}
              xs={12}
            >
              <VideoPlayer currentVideo={this.state.currentVideo} />
              <Grid
                item
                xs={12}
              >
                <Contact />
              </Grid>
            </Grid>
            <Grid
              item
              md={5}
              xs={12}
            >
              <List
                currentVideo={this.state.currentVideo}
                handleVideo={this.handleVideo}
                pdf={this.state.pdf}
                videos={this.state.videos}
              />
            </Grid>
          </Grid>
        </div>
      </DashboardLayout>
    );
  }
}

Settings.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Settings);
