import React from 'react';
import {
  Portlet,
  PortletContent,
  PortletHeader,
  PortletLabel
} from 'components';
import { Link, Typography } from '@material-ui/core';

function Contact() {
  return (
    <Portlet style={{ marginTop: 30 }}>
      <PortletHeader>
        <PortletLabel title="Contacto" />
      </PortletHeader>
      <PortletContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 10
        }}
      >
        <Typography
          style={{ color: '#730065' }}
          variant="h6"
        >
          Atención a Clientes:
          <Link
            href="https://api.whatsapp.com/send?phone=5218182597128&text=%C2%A1Hola%F0%9F%98%83!%2C%20Podr%C3%ADas%20ayudarme%20con..."
            style={{ color: 'black', marginLeft: 5 }}
          >
            +52 (81) 8259 7128
          </Link>
        </Typography>
        <Typography
          style={{ color: '#730065' }}
          variant="h6"
        >
          Ventas:
          <Link
            href="tel:+528112533921"
            style={{ color: 'black', marginLeft: 5 }}
          >
            +52 (81) 1253 3921
          </Link>
        </Typography>
        <Typography
          style={{ color: '#730065' }}
          variant="h6"
        >
          Correo:
          <Link
            href="mailto:atencion@metimur.tech"
            style={{ color: 'black', marginLeft: 5 }}
          >
            atencion@metimur.tech
          </Link>
        </Typography>
      </PortletContent>
    </Portlet>
  );
}

export default Contact;
